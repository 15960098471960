<template>
  <div :class="['sidebar', { 'small-screen': isSmallScreen, 'hidden': !isSidebarVisible }]">
    <form @submit.prevent="generateReport" class="new-report-form">
      <input v-model="newReport.topic" placeholder="Enter topic" required class="form-control" />
      <input v-model="newReport.concerns" placeholder="Concerns (comma-separated)" class="form-control" />
      <button type="submit" class="btn btn-primary">
        Generate Report
      </button>
    </form>

    <div class="sidebar-separator"></div>

    <h3 class="searches-title">Searches</h3>
    <input v-model="searchQuery" placeholder="Search reports..." class="form-control search-input" />

    <ul v-if="filteredReports.length > 0" class="report-list">
    <li
      v-for="report in filteredReports"
      :key="report.id"
      @click="report.status !== 'loading' && loadReport(report.id)"
      :class="['enhanced-list-item', { 'loading-item': report.status === 'loading' }]"
    >
      <div class="enhanced-list-item-content">
        <div class="enhanced-list-item-title">{{ report.title }}</div>
        <div class="enhanced-list-item-subtitle">{{ formatDate(report.date) }}</div>
        <div v-if="report.status === 'loading'" class="report-status">
          <span class="glowing-circle"></span>
          <span class="loading-text">Generating report...</span>
        </div>
      </div>
    </li>
  </ul>
    <p v-else class="no-reports">No reports found</p>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';

export default {
  name: 'SidebarComponent',
  props: {
    isSidebarVisible: Boolean,
    isSmallScreen: Boolean,
  },
  emits: ['toggle-sidebar', 'report-generated', 'report-selected'],
  setup(props, { emit }) {
    const searchQuery = ref('');
    const reports = ref([]);
    const newReport = ref({
      topic: '',
      concerns: ''
    });
    const generatingReports = ref([]);

    const allReports = computed(() => {
      const combined = [...reports.value, ...generatingReports.value];
      return combined
        .filter(report =>
          report.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    });

    const filteredReports = computed(() => allReports.value);

    const generateReport = async () => {
      const tempId = `temp-${Date.now()}`;
      const tempReport = {
        id: tempId,
        title: newReport.value.topic,
        date: new Date().toISOString(),
        status: 'loading',
      };
      generatingReports.value.push(tempReport); // Use push to maintain chronological order

      // Clear input fields immediately
      newReport.value = { topic: '', concerns: '' };

      try {
        const requestData = {
          topic: tempReport.title,
          user_concerns: newReport.value.concerns
            ? newReport.value.concerns.split(',').map(c => c.trim()).filter(c => c)
            : [],
          limit: 20,
          comments_limit: 5
        };
        const API_URL = process.env.VUE_APP_SERVER_ENDPOINT || 'https://ara-demo-backend.vercel.app'
        const response = await axios.post(`${API_URL}/topic-analysis`, requestData);

        if (!response.data || !response.data.report) {
          throw new Error('Invalid response from server');
        }

        const newReportData = {
          id: Date.now(),
          title: tempReport.title,
          date: new Date().toISOString(),
          content: response.data.report,
        };

        // Remove from generatingReports
        generatingReports.value = generatingReports.value.filter(r => r.id !== tempId);
        // Add to reports
        reports.value.push(newReportData);

        emit('report-generated', { report: newReportData, posts: response.data.posts });
      } catch (error) {
        console.error('Error generating report:', error);
        alert(`Failed to generate report: ${error.message}`);
        // Remove from generatingReports in case of error
        generatingReports.value = generatingReports.value.filter(r => r.id !== tempId);
      }
    };

    const loadReport = (id) => {
      const report = reports.value.find(r => r.id === id);
      if (report) {
        emit('report-selected', report);
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
      return date.toLocaleString('en-US', options);
    };

    const handleSuggestedTopic = (topic) => {
      newReport.value.topic = topic;
      generateReport();
    };

    return {
      searchQuery,
      reports,
      generatingReports,
      newReport,
      filteredReports,
      generateReport,
      loadReport,
      formatDate,
      handleSuggestedTopic,
    };
  }
};
</script>

<style scoped>
/* Original: --primary-color: #0066CC; */
.sidebar {
  width: 300px;
  height: 100%;
  background-color: white;
  border-right: 1px solid #e0e0e0; /* Original: --border-color */
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  /* Removed box-shadow */
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.new-report-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #e0e0e0; /* Original: --border-color */
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  color: #333; /* Original: --text-color */
}

.form-control::placeholder {
  color: #6b7280; /* Original: --text-muted */
}

.form-control:focus {
  border-color: #0066CC; /* Original: --primary-color */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 204, 0.25);
}

.btn {
  padding: 16px 16px; /* Increased padding for taller button */
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: #0066CC; /* Original: --primary-color */
  color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added small shadow */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: #005bb5; /* Darken on hover */
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 0 3px;
  animation: fade 1.5s infinite ease-in-out;
}

@keyframes fade {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.sidebar-separator {
  width: 100%;
  border-top: 1px solid #e0e0e0; /* Original: --border-color */
  margin: 20px 0;
}

.searches-title {
  font-size: 1.2em;
  color: #0066CC; /* Original: --primary-color */
  margin-bottom: 10px;
}

.search-input {
  margin-bottom: 10px;
}

.report-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.enhanced-list-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0; /* Original: --border-color */
  transition: background-color 0.3s ease;
  position: relative;
}

.enhanced-list-item:hover {
  background-color: rgba(0, 102, 204, 0.05);
}

.loading-item {
  opacity: 0.6;
  cursor: default;
}

.enhanced-list-item-title {
  font-weight: 600;
  font-size: 14px;
}

.enhanced-list-item-subtitle {
  font-size: 12px;
  color: #6b7280; /* Original: --text-muted */
}

.report-status {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  color: #6b7280; /* Original: --text-muted */
}

.loading-spinner {
  width: 10px;
  height: 10px;
  border: 2px solid #6b7280;
  border-top: 2px solid transparent;
  border-radius: 50%;
  margin-right: 5px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.no-reports {
  text-align: center;
  color: #6b7280; /* Original: --text-muted */
  font-style: italic;
  margin-top: 20px;
}

.sidebar.small-screen {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  z-index: 1000;
  width: 100%;
  max-width: 300px;
}

.sidebar.small-screen.hidden {
  transform: translateX(-100%);
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 300px;
  }
}

.glowing-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0066CC; /* Start with blue */
  margin-right: 8px;
  animation: glow 1.5s infinite, colorChange 45s linear forwards; /* Use forwards to keep the final state */
}

.loading-text {
  display: flex;
  align-items: center;
  color: #6b7280; /* Text-muted color */
  font-size: 12px;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.3);
    opacity: 0.6;
  }
  50% {
    box-shadow: 0 0 15px rgba(0, 102, 204, 0.8);
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.3);
    opacity: 0.6;
  }
}

@keyframes colorChange {
  0% {
    background-color: #0066CC; /* Blue */
  }
  100% {
    background-color: #00FF00; /* Vibrant Green */
  }
}

.report-status {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
</style>
